<template>
  <div>
    <el-dialog
      title="报价师成交统计"
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="1200px"
    >
      <GardenSlwct
        :tab-data="tabData"
        :activeIndex="activeIndex"
        width="90px"
        border-radius="40px"
        @tabAction="tabAction"
        style="margin-bottom: 10px;"
      >
      </GardenSlwct>
      <div style="margin-bottom: 20px">
        <span>查询商家：{{ row.name }}</span>
        <span style="margin-left: 30px">查询时间：{{ showDate }}</span>
      </div>

      <el-table v-if="visible" v-loading="loading" :data="list" border :default-sort="{ prop: 'dealCount', order: 'descending' }">
        <el-table-column type="index" label="序号" width="70" align="center" />
        <el-table-column label="报价师" prop="name" align="center">
          <template slot-scope="{ row }">
            <p>{{ row.name }} - {{row.mobile}}</p>
            <p>{{ optionsFilter(row.positionType, positionTypeOptions) }}</p>
          </template>
        </el-table-column>
        <el-table-column label="报价量" prop="quoteCount" align="center" sortable :sort-orders="['descending', 'ascending']" />
        <el-table-column label="拒绝报价量" prop="rejectQuoteNum" sortable :sort-orders="['descending', 'ascending']" align="center">
          <template slot-scope="{ row }">
            <el-link v-if="row.rejectQuoteNum" type="danger" :underline="false">{{row.rejectQuoteNum}}</el-link>
            <span v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column label="低价拒绝量" prop="rejectLowQuoteNum" sortable :sort-orders="['descending', 'ascending']" align="center">
          <template slot-scope="{ row }">
            <el-link v-if="row.rejectLowQuoteNum" type="danger" :underline="false">{{row.rejectLowQuoteNum}}</el-link>
            <span v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column label="有效报价量" prop="normalQuoteNum" align="center" sortable :sort-orders="['descending', 'ascending']" />
        <el-table-column label="成交量" prop="dealCount" align="center" sortable :sort-orders="['descending', 'ascending']" />
        <el-table-column label="成交率" prop="dealRate" align="center" sortable :sort-orders="['descending', 'ascending']">
          <template slot-scope="{ row }">
            {{ row.dealRate }}%
          </template>
        </el-table-column>
        <el-table-column label="最高报价率" prop="quoteMaxRate" align="center" sortable :sort-orders="['descending', 'ascending']">
          <template slot-scope="{ row }">
            {{ row.quoteMaxRate }}%
          </template>
        </el-table-column>
        <el-table-column label="累计成交金额（元）" prop="dealPrice" align="center" sortable :sort-orders="['descending', 'ascending']" />
        <el-table-column label="平均报价速度（秒）" prop="avgSpeed" align="center" sortable :sort-orders="['descending', 'ascending']" />
      </el-table>
      <span slot="footer"></span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import _api from "@/utils/request";
import GardenSlwct from "@/components/common/gardenSlwct.vue";

export default {
  name: "MerchantDealStatisticsDia",
  props: ['dateType', 'dateRange', 'startTime', 'endTime'],
  components: {
    GardenSlwct,
  },
  data() {
    return {
      visible: false,
      loading: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      list: [],
      sortForm: {},
      row: {},
      positionTypeOptions: [
        { label: '子账号', value: '01' },
        { label: '报价师', value: '02' },
      ],
      dateTypeList: [
        { label: '今日', value: '01'},
        { label: '本周', value: '03'},
        { label: '本月', value: '05'},
      ],
      activeIndex: 0,
      quoteChannel: '00',
      tabData: [
        {
          text: '全部',
          value: '00',
          hideNum: true,
        },{
          text: '审单报价',
          value: '01',
          hideNum: true,
        },{
          text: '快捷报价',
          value: '02',
          hideNum: true,
        }
      ]
    };
  },
  computed: {
    showDate() {
      const { dateType, dateRange } = this
      const [startTime, endTime] = dateRange || []
      if (dateType) return this.dateTypeList.find(item => item.value == dateType)?.label
      return `${moment(startTime).format("YYYY.MM.DD")} - ${moment(endTime).format("YYYY.MM.DD")}`
    }
  },
  methods: {
    open(row) {
      this.row = row
      this.visible = true
      this.getData()
    },
    getData() {
      this.loading = true
      const [startTime, endTime] = this.dateRange || []
      console.log(this.dateRange)
      _api.getMerchantRankStaffDealStatistics({
        merchantId: this.row.id,
        dateType: this.dateType,
        quoteChannel: this.quoteChannel,
        startTime,
        endTime,
      }).then(res => {
        this.list = res.data
      }).finally(() => {
        this.loading = false
      })
    },
    optionsFilter(v, options) {
      return options.find(item => item.value == v)?.label
    },
    tabAction(v){
      this.quoteChannel = this.tabData[v].value
      this.getData()
    }
  }
};
</script>
<template>
  <div class="TableOrder">
    <div class="case-title">
      <div class="icon_title">
        <div>
          <img class="case-title_icon" src="@/assets/images/imgAll/icon_ddtj_.png" alt />
        </div>
        <div class="case-title_max">品牌价位段统计</div>
        <!-- <el-dropdown @command="merchantAction" trigger="click" v-if="tableShow">
          <span class="el_dropdown_link">
            {{ isManufacturers==''?'全部商家':isManufacturers=='01'?'KA商家':"厂家" }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="">全部商家</el-dropdown-item>
            <el-dropdown-item command="01">KA商家</el-dropdown-item>
            <el-dropdown-item command="00">厂家</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
    </div>
    <div style="position: relative; min-height: 200px">
      <Brandtable v-if="tableShow" :merchantSelectList="merchantSelectList" :companySelectList="companySelectList" :isManufacturers="isManufacturers" />
      <Shadow @confirm="tableShow = true" />
    </div>
  </div>
</template>
<script>
import Shadow from './components/Shadow.vue'
import Brandtable from './table/Brandtable.vue'
export default {
  name: 'BrandPrice',
  components: { Brandtable, Shadow },
  props:['merchantSelectList', 'companySelectList'],
  data(){
    return{
      tableShow: false,
      isManufacturers: ''
    }
  },
  created(){

  },
  methods:{
    merchantAction(e){
      console.log(e);
      this.isManufacturers=e
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.TableOrder{
    width: 100%;
    // min-height: 360px;
    background-color: white;
    .case-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid #f6f6f6;
      .icon_title {
        display: flex;
        .case-title_icon {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
        .case-title_max {
          display: inline-block;
          width: 180px;
          height: 17px;
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
  .el_dropdown_link {
    color: #409eff;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
  }
}
</style>
